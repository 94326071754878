import React from 'react';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Button, Grid } from '@material-ui/core';
import NotFoundImage from '../components/ui/svg/NotFoundImage';
import BackgroundMap from '../components/ui/svg/BackgroundMap';
import { useRouter } from 'next/router';
import PageHeadTags from '../components/PageHeadTags';
import { Home_PAGE } from '../utils/pagesUrls';

const AboutPage = () => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const goHome = () => {
    router.push(Home_PAGE, null, { shallow: true });
  };

  return (
    <>
      <PageHeadTags title={t('not_found_title')} />

      <Grid container className="notfound-page-container">
        <Grid className="notfound-page-cntent" container direction="column" alignItems="center" justifyContent="center">
          <BackgroundMap />
          <NotFoundImage />
          {/* <Grid className="notfound-title">{t('ops')}</Grid> */}
          <Grid className="notfound-sub-title">{t('not_found')}</Grid>
          <Button className="notfound-sub-btn" onClick={() => goHome()}>
            {t('return_homepage')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'footer', 'menu', 'search', 'error', 'actuality', 'cookies', 'newsletter', 'candidature'])),
    },
  };
}

export default AboutPage;
